export const getLocationLink = (municipality, locationType, name, id) => {
  return `/platser/${municipality}/${locationType}/${name}/${id}`.toLowerCase();
};

export const getLocationLinkExternal = (
  municipality,
  locationType,
  name,
  id
) => {
  const BASE_URL = process.env.REACT_APP_FRONTEND_URL;
  return `${BASE_URL}platser/${municipality}/${locationType}/${name}/${id}`.toLowerCase();
};
