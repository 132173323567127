import React from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { pages } from '../../../state/page';

import FooterSection from './FooterSection';
import IconLinkButton from '../buttons/IconLinkButton';

import SplashImage from '../../../assets/images/splash.svg';

const Footer = () => {
  const allPages = useRecoilValue(pages);

  const sections = [
    {
      title: 'Kategorier',
      items: [{ id: 1, title: 'Lekplatser', slug: '/?type=50' }],
    },
    {
      title: 'Om oss',
      items: allPages?.map((page) => {
        return {
          slug: `sida/${page.slug}`,
          title: page.title,
        };
      }),
    },
    {
      title: 'Tillgänglighet',
      items: [
        {
          title: 'Vårt arbete',
          slug: 'sida/om-oss',
        },
        {
          title: 'Synpunkter',
          slug: 'sida/kontakt',
        },
      ],
    },
    {
      title: 'Våra partners',
      items: [
        {
          title: 'Familjen Helsingborg',
          slug: 'https://familjenhelsingborg.se/',
        },
        {
          title: 'Höganäs kommun',
          slug: 'https://www.hoganas.se/',
        },
        {
          title: 'Helsingborgs stad',
          slug: 'https://helsingborg.se/',
        },
        {
          title: 'Klippans kommun',
          slug: 'https://www.klippan.se/',
        },
        {
          title: 'Ängelholms kommun',
          slug: 'https://www.engelholm.se/',
        },
        {
          title: 'Ensolution Software',
          slug: 'https://ensolution.se/',
        },
      ],
    },
  ];

  const asideItems = [
    {
      title: 'Integritetspolicy',
      slug: 'sida/integritetspolicy',
    },
    {
      title: 'Cookiespolicy',
      slug: 'sida/cookiespolicy',
    },
  ];

  return (
    <footer
      className="bg-accent mt-auto dont-print-this
    px-6 md:px-32 pt-5 pb-20 md:pb-8 xl:pb-10 flex"
    >
      <div
        className="relative flex flex-col md:flex-row justify-between
      space-y-16 md:space-y-0
       w-full overflow-hidden"
      >
        <section className="md:size-64">
          <div className="bg-primary h-full w-full rounded-2xl p-4 text-secondary flex flex-col justify-between items-start relative group overflow-hidden">
            <h2 className="text-3xl lg:text-2xl xl:text-2xl font-medium z-[1]">
              Gör samhället mer tillgängligt!
            </h2>
            <div className="text-xl md:text-lg xl:text-xl z-[1]">
              Tillsammans skapar vi mer tillgängliga platser för alla i
              samhället, en plats i taget.
            </div>
            <IconLinkButton
              text="Tips oss"
              buttonStyle="z-[1] md:h-8"
              href="/sida/kontakt"
            />
            <img
              className="absolute bottom-0 left-0 
          transition-all group-hover:scale-150 duration-[1s]"
              src={SplashImage}
              alt="Illustrationsbild"
            />
          </div>
        </section>

        {sections.map((section, idx) => (
          <FooterSection
            key={idx}
            style={section.style}
            title={section.title}
            items={section.items}
          />
        ))}

        <aside className="absolute right-0 bottom-0">
          <ul className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-8">
            {asideItems.map((item, idx) => (
              <li key={idx}>
                <Link className="hover:underline text-base" to={item.slug}>
                  {item.title}
                </Link>
              </li>
            ))}
          </ul>
        </aside>
      </div>
    </footer>
  );
};

export default Footer;
