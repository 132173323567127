import React from 'react';
import PropTypes from 'prop-types';
import getTagIcon from '../../util/getTagIcon';
import tagType from '../../config/tagType';

const LocationDetailsTagsList = ({ accessibilityTags }) => {
  return (
    <div className="flex flex-col items-start">
      <h3 className="text-2xl text-text1 font-medium mb-4 pb-4">
        Denna plats är anpassad för
      </h3>
      <div className="flex gap-6 flex-wrap">
        {accessibilityTags?.map((item, idx) => (
          <div key={idx} className="flex space-x-6 items-start">
            <div className="mt-2">
              {getTagIcon(item.name, tagType.ACCESSIBILITY, '26')}
            </div>
            <div className="">
              <h3 className="text-text1 text-md font-medium">{item.label}</h3>
              <p className="text-text2 text-md">{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

LocationDetailsTagsList.propTypes = {
  accessibilityTags: PropTypes.array,
};

export default LocationDetailsTagsList;
