import React from 'react';
import propTypes from 'prop-types';
import getIcon from '../../../util/getIcon';
import icons from '../../../config/icons';
import { useNavigate } from 'react-router-dom';

const BackButton = ({
  buttonStyle,
  textStyle,
  hideTextOnMobile = false,
  isDark = true,
  isDisabled,
}) => {
  const navigate = useNavigate();
  return (
    <button
      disabled={isDisabled}
      className={`
        ${isDisabled ? 'opacity-50' : ''}
        h-10 flex items-center space-x-2 rounded-full dont-print-this
        ${isDark ? 'bg-primary text-secondary border-transparent' : 'bg-secondary text-primary border-border'}
        ${isDark ? 'hover:bg-secondary hover:text-primary hover:border-primary' : 'hover:bg-primary hover:text-secondary hover:border-transparent'}
        border 
        transition-all text-md rounded-full p-4
         ${buttonStyle}
         `}
      onClick={() => navigate(-1)}
    >
      {getIcon(icons.ARROW, '-rotate-90')}
      <p
        className={`whitespace-nowrap ${hideTextOnMobile ? 'hidden md:block' : ''}
      ${textStyle ? textStyle : ''}
      'w-full`}
      >
        Gå tillbaka
      </p>
    </button>
  );
};

BackButton.propTypes = {
  buttonStyle: propTypes.string,
  textStyle: propTypes.string,
  hideTextOnMobile: propTypes.bool,
  isDark: propTypes.bool,
};

export default BackButton;
